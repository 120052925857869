
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$lucky-draw-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));
@font-face {
  font-family: "Monstro";
  src: url("/assets/fonts/monstro_solid/MonstroSolid.otf");
}
@font-face {
  font-family: "Futura";
  src: url(/assets/fonts/futura/Futura.ttc);
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($lucky-draw-theme);
  --alto: #d9d9d9;
  --black: #000000;
  --black-2: #000000cc;
  --cultured-pearl: #f5f5f5;
  --eerie-black: #1e1e1e;
  --heavy-metal: #2c2c2c;
  --nobel: #b3b3b3;
  --red: #fc0202;
  --white: #ffffff;
  --yellow-sunshine: #fff100;
  --font-size-l: 16px;
  --font-size-m: 12px;
  --font-size-xl: 22.4px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 48px;
  --font-family-changa: "Changa", Helvetica;
  --font-family-changa_one: "Changa One", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --monstro: "Monstro";
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($lucky-draw-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($lucky-draw-theme);
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Changa+One:400|Changa:400|Inter:400|Roboto:500,400");
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.grecaptcha-badge{
  transform:scale(0.5) !important;
  transform-origin:0 0 !important;
  bottom: 50px !important;
  right: -300px !important;
}
.font-secondary {
  font-family: var(--monstro);
  text-transform: uppercase;

  * {
    text-transform: uppercase;
  }
}

.button {
  background: var(--black-2)!important;
  color: white!important;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 12px;
}